import React, { useCallback, useState } from 'react'

import { Text } from '@cash-me/react-components'

import { pushDataLayer } from 'utils/push-data-layer'

import * as S from './styles'
import { TabProps, TabsProps } from './tabs.model'

export const Tabs = ({ activeTabIndex, children, dataLayer }: TabsProps) => {
  const [selectedTab, setSelectedTab] = useState<number>(activeTabIndex)

  const handleSelectTab = useCallback(
    (index: number) => {
      setSelectedTab(index)

      if (!dataLayer) return
      const data = dataLayer.find(item => item.index === index)
      if (data) pushDataLayer(data)
    },
    [dataLayer]
  )

  const renderTabs = useCallback(
    () =>
      React.Children.map(children, (child, index) => {
        if (!React.isValidElement<TabProps>(child)) return

        return (
          <S.Tab data-state={selectedTab === index ? 'active' : 'inactive'} onClick={() => handleSelectTab(index)}>
            <Text as="span" size="caption" uppercase color="var(--colors-neutral-text-weaker)">
              {child.props?.name}
            </Text>
          </S.Tab>
        )
      }),
    [children, selectedTab, handleSelectTab]
  )

  const renderTabContent = useCallback(
    () =>
      React.Children.map(children, (child, index) => (
        <S.TabContent data-state={selectedTab === index ? 'active' : 'inactive'} $active={selectedTab === index}>
          {child}
        </S.TabContent>
      )),
    [children, selectedTab]
  )

  return (
    <>
      <S.TabList>{renderTabs()}</S.TabList>
      <S.TabContentWrapper>
        <S.Movable style={{ transform: `translateX(-${selectedTab * 100}%)` }}>{renderTabContent()}</S.Movable>
      </S.TabContentWrapper>
    </>
  )
}
